<template>
  <QLayout
    view="hhr LpR fFr"
    class="bg-grey-2"
  >
    <QHeader
      v-if="!hideHeader"
      :reveal-offset="100"
      class="bg-white q-py-sm"
    >
      <QToolbar class="bg-white">
        <a
          class="logo-container anchor-text--reset cursor-pointer q-mr-sm text-primary full-width flex justify-center"
          :href="appHomeUrl"
          :aria-label="$t({ id: 'navigation.home' })"
          flat
        >
          <AppLogo class="q-mr-sm" />
        </a>
      </QToolbar>
    </QHeader>

    <QPageContainer>
      <router-view v-slot="{ Component }">
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
          appear
          :duration="300"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </QPageContainer>
    <CookiePopup />
  </QLayout>
</template>

<script>
import AppLogo from 'hc-core/components/common/app-logo'
import CookiePopup from 'hc-core/components/popups/cookie-popup'

export default {
  components: { AppLogo, CookiePopup },
  computed: {
    appHomeUrl () { return process.env.HC_APP_URL },
    hideHeader () { return this.$_.get(this.$route, 'meta.hideHeader', false) }
  }
}
</script>
